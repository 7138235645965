export function addIncludeKey(orderData) {
  orderData?.map((obj) => {
    // ADD INCLUDE FOR CHECK ORDER AND FOR CUTT THE ITEM IN TAKE AWAY.
    let data = obj?.take_away?.map((item) => {
      let updateProduct = item?.product?.map((productItem) => {
        productItem.include = productItem.include || false;
      });
      return updateProduct;
    });
    return data;
  });

  // ADD INCLUDE FOR CHECK ORDER AND FOR CUTT THE ITEM IN DINE IN.
  orderData.map((obj) => {
    let data = obj?.dine_in?.map((item) => {
      let updateProduct = item?.product?.map((productItem) => {
        productItem.include = productItem.include || false;
      });
      return updateProduct;
    });
    return data;
  });
  return orderData;
}
