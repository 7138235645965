import { Box } from "@mui/material";
import React from "react";

function AccessDenied() {
  return (
    <>
      <Box sx={{ textAlign: "center", py: "50px" }}>
        <img src="/access_denied.png" alt="Access Denied" />
      </Box>
    </>
  );
}

export default AccessDenied;
