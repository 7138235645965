import { Button, Card, CardContent, Grid } from "@mui/material";
import React from "react";

function ButtonBar({
  activeBtn,
  currentStatus,
  tabBtnStatus,
  activeNewOldBtn,
}) {
  const handleChange = (name: string) => {
    activeNewOldBtn(name);
  };

  return (
    <>
      <Card className="cardDesign">
        <CardContent style={{ paddingBottom: "15px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={1.5} lg={2} xl={1.4}>
              <Button
                variant={tabBtnStatus == "newOrder" ? "contained" : "outlined"}
                size="small"
                color="warning"
                fullWidth
                onClick={() => handleChange("newOrder")}
              >
                Running
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={2.5} lg={1.7} xl={1.4}>
              <Button
                variant={
                  tabBtnStatus == "readyToPick" ? "contained" : "outlined"
                }
                size="small"
                color="warning"
                fullWidth
                onClick={() => handleChange("readyToPick")}
              >
                Ready To Pick
              </Button>
            </Grid>
            <Grid item xs={12} sm={3.5} md={2} lg={2} xl={1.5}>
              <Button
                variant={
                  tabBtnStatus == "completeOrder" ? "contained" : "outlined"
                }
                // className="customBtn"
                size="small"
                fullWidth
                color="warning"
                onClick={() => handleChange("completeOrder")}
              >
                Completed
              </Button>
            </Grid>
            <Grid item xs={12} sm={3.5} md={1.5} lg={2} xl={1.5}>
              <Button
                variant={tabBtnStatus == "voidOrder" ? "contained" : "outlined"}
                // className="customBtn"
                size="small"
                fullWidth
                color="warning"
                onClick={() => handleChange("voidOrder")}
              >
                Void
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={0.5}
              lg={0.7}
              xl={3.8}
              sx={{ display: { xs: "none", md: "block" } }}
            ></Grid>
            <Grid
              item
              xs={4}
              sm={1.5}
              md={1}
              lg={1}
              xl={0.7}
              sx={{ textAlign: { xs: "center" } }}
            >
              <Button
                variant={currentStatus == "all" ? "contained" : "outlined"}
                // className="customBtn"
                color="warning"
                fullWidth
                size="small"
                onClick={() => activeBtn("all")}
              >
                All
              </Button>
            </Grid>
            <Grid
              item
              xs={4}
              sm={1.5}
              md={1}
              lg={1}
              xl={0.7}
              sx={{ textAlign: { xs: "center" } }}
            >
              <Button
                variant={currentStatus == "dining" ? "contained" : "outlined"}
                // className="customBtn"
                color="warning"
                fullWidth
                size="small"
                onClick={() => activeBtn("dining")}
              >
                Dining
              </Button>
            </Grid>
            <Grid
              item
              xs={4}
              sm={2.5}
              md={2}
              lg={1.5}
              xl={1}
              sx={{ textAlign: { xs: "center" } }}
            >
              <Button
                variant={
                  currentStatus == "take_away" ? "contained" : "outlined"
                }
                color="warning"
                fullWidth
                size="small"
                onClick={() => activeBtn("take_away")}
              >
                Take Away
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default ButtonBar;
