import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import Chip from "@mui/material/Chip";
import ButtonBar from "./ButtonBar.tsx";
import PageNotFound from "./PageNotFound.tsx";
import socketIO from "./Controllers/socket.tsx";
// import io, { Socket } from "socket.io-client";
import { ApiStatus } from "../api/api_url.ts";
import { GToaster } from "../helper/g_toaster.tsx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useRoutes, useSearchParams } from "react-router-dom";
import { StoreController } from "./Controllers/store_controller.tsx";
import TableBarTwoToneIcon from "@mui/icons-material/TableBarTwoTone";
import {
  setReloadCounter,
  setStore,
  setStoreDayDetails,
} from "./Redux_Store/Slices/StoreSlice.js";
import AlarmOnSharpIcon from "@mui/icons-material/AlarmOnSharp";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import { textUpperCase } from "../helper/g_constants.ts";
import ChairAltTwoToneIcon from "@mui/icons-material/ChairAltTwoTone";
import { SocketController } from "./Controllers/socketController.tsx";
import {
  setAllVoidOrder,
  setCancelOrder,
  setCompleteOrder,
  setTotalOrder,
} from "./Redux_Store/Slices/OrderSlice.js";
import Shimmer from "./Shimmer.tsx";
import {
  getProductItems,
  setNotifyOrders,
} from "./Redux_Store/Slices/OrderNotify.js";
import Counter from "./Counter.tsx";
import TakeAwayDineIn from "./MapingSection/TakeAwayDineIn.tsx";
import TakeAwayVoidDineInVoid from "./MapingSection/TakeAwayVoidDineInVoid.tsx";
import { addIncludeKey } from "../Helpers/Helper.js";

const gToaster = new GToaster();
const storeController = new StoreController();
// const socketController = new SocketController();
const orderbell = "sound/notification_sound.mp3";
const sound = new Audio(orderbell);
function Home({ isActive }) {
  const GridNumber = useSelector((state: any) => state.orders?.gridNum);
  const counter = useSelector((state: any) => state.store.reloadCounter);
  const socket = socketIO();
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dayId, setDayId] = useState<string>("");
  const [notFound, setNotFound] = useState(false);
  const [btnIndex, setBtnIndex] = useState<number>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentStatus, setCurrentStatus] = useState("all");
  const [notifyloading, setNotifyLoading] = useState(false);
  const [tabBtnStatus, setTabBtnStatus] = useState("newOrder");
  const [readyToPickLoading, setReadyToPickLoading] = useState(false);
  const [completeVoidOrders, setCompleteVoidOrders] = useState<any[]>([]);
  const [orderdata, setorderdata] = useState<any[]>([]);
  const [backupOrderdata, setBackupOrderdata] = useState<any>([]);
  const storeId = searchParams.get("id");

  // const [connection, setConnection] = useState(false);
  // const [voidOrders, setVoidOrders] = useState<any[]>([]);
  // let ghostOrders: Array<Record<string, any>> = [];

  // const SOCKETURL = process.env.NODE_ENV == "development"   ? "ws://localhost:9999"     : "wss://loopbackendnew.loop.rockymountaintech.co";
  // SOCKET CONNECTION
  // const socket: Socket = io(
  //   `wss://loopbackendnew.loop.rockymountaintech.co?store=${storeId}`,
  //   {
  //     transports: ["websocket"],
  //     autoConnect: true,
  //   }
  // );

  //========================
  // GET STORE PROFILE DATA
  async function getStoreProfile() {
    let response = await storeController.getStoreDetails({ _id: storeId });
    if (response.status == ApiStatus.STATUS_200) {
      if (!response.data.data.store_setting.general_setting.kds_status) {
        navigate(`/?_id=${storeId}/access-denied`);
      }
      dispatch(setStore(response.data.data));
    } else if (response.status == ApiStatus.STATUS_500) {
      gToaster.warning({ title: "500 Server Error" });
    } else {
      gToaster.warning({ title: " Server Error" });
    }
  }

  //======================
  //ACTIVE BUTTON FUNCTION
  function handleActive(name: string) {
    setCurrentStatus(name);
  }

  //==================
  // GET STORE DAY ID API
  async function getStoreDay() {
    let response = await storeController.getStartDay({ _id: storeId });
    if (response.status == ApiStatus.STATUS_200) {
      if (response.data.data?.day_id) {
        // socketController.connect(storeId, ordersSliceData);
        //IF WE GET STORE DAY ID, MEANS STORE IS OPEN THEN CONNECT SOCKET & PASS DAY_ID TO API USE TO GET ALL ORDER, VOID AND COMPLETED ORDERS.
        let dayid = response.data.data?.day_id;
        setDayId(dayid);
        dispatch(setStoreDayDetails(response.data.data));
        if (!!dayid) {
          getStoreVoidOrdersData(dayid);
          getStoreOrdersData(dayid);
        }
      } else {
        navigate("/storeclose");
      }
    } else if (response.status == ApiStatus.STATUS_500) {
      gToaster.warning({ title: "500 Server Error" });
    } else {
      gToaster.warning({ title: " Server Error" });
    }
  }

  //===========
  // GET All ORDERS API
  async function getStoreOrdersData(startDayId) {
    setLoading(true);
    let response = await storeController.getStoreOrders({
      day_id: startDayId,
      store_Id: storeId,
    });
    if (response.status == ApiStatus.STATUS_200) {
      setLoading(false);
      dispatch(setTotalOrder(response?.data?.data?.length));

      let orderArr = [...response?.data?.data];
      let newOrderData = addIncludeKey(orderArr);

      setBackupOrderdata(newOrderData);
      getFilteredOrderFun({ name: tabBtnStatus, myData: newOrderData });

      // dispatch(setAllOrder(response.data.data));
    } else if (response.status == ApiStatus.STATUS_500) {
      gToaster.warning({ title: "500 Server Error" });
      setLoading(false);
    } else {
      gToaster.warning({ title: "Server Error" });
      setLoading(false);
    }
  }
  //================
  // GET All VOID ORDERS API
  async function getStoreVoidOrdersData(startDayId) {
    let response = await storeController.getStoreVoidOrders({
      day_id: startDayId,
      store_Id: storeId,
    });
    if (response.status == ApiStatus.STATUS_200) {
      let compeleteVoidOrders: Array<any> = response.data.data;
      setCompleteVoidOrders(compeleteVoidOrders);
      dispatch(setCompleteOrder(compeleteVoidOrders?.length));
    } else if (response.status == ApiStatus.STATUS_500) {
      gToaster.warning({ title: "500 Server Error" });
    } else {
      gToaster.warning({ title: "Server Error" });
    }
  }

  //==========
  // EDIT ORDERS
  async function handleCutItem(
    orderType,
    mainIndex,
    parentProductIndex,
    childProductIndex,
    value,
    orderId
  ) {
    let data: Array<Record<string, any>> = JSON.parse(
      JSON.stringify([...orderdata])
    );

    data[mainIndex][orderType][parentProductIndex]["product"][
      childProductIndex
    ]["include"] = value;

    setorderdata([...data]);
    // dispatch(getProductItems([orderId, itemId._id]));
  }

  // API FOR ACCEPT ORDER BY CHEF
  async function handleAcceptOrder(obj: Record<string, any>, index: number) {
    // dispatch(setNotifyOrders(obj));
    setNotifyLoading(true);
    setBtnIndex(index);
    await storeController
      .accpetOrder({ _id: obj._id })
      .then((response: any) => {
        const newOrderData = response?.data?.data;
        const clonedBackupOrderdata = JSON.parse(
          JSON.stringify(backupOrderdata)
        );
        const findIndexData = backupOrderdata.findIndex(
          (item: any) => item._id === newOrderData._id
        );
        clonedBackupOrderdata.splice(findIndexData, 1, newOrderData);
        setBackupOrderdata(clonedBackupOrderdata);
        // activeNewCancelCompleteOrders("newOrder");
        setNotifyLoading(false);
        getFilteredOrderFun({
          name: "newOrder",
          myData: clonedBackupOrderdata,
        });
        if (response.status == ApiStatus.STATUS_200) {
        } else if (response.status == ApiStatus.STATUS_500) {
          gToaster.warning({ title: "500 Server Error" });
        } else {
          gToaster.warning({ title: " Server Error" });
        }
      });
  }

  async function handleReadyToPick(obj: Record<string, any>, index: number) {
    setBtnIndex(index);
    setReadyToPickLoading(true);
    await storeController
      .readyToPickOrder({ _id: obj._id })
      .then((response: any) => {
        const newOrderData = response?.data?.data;
        const clonedBackupOrderdata = JSON.parse(
          JSON.stringify(backupOrderdata)
        );
        const findIndexData = backupOrderdata.findIndex(
          (item: any) => item._id === newOrderData._id
        );
        clonedBackupOrderdata.splice(findIndexData, 1, newOrderData);
        setBackupOrderdata([...clonedBackupOrderdata]);
        // setorderdata([...clonedBackupOrderdata]);

        setReadyToPickLoading(false);

        getFilteredOrderFun({
          name: tabBtnStatus,
          myData: clonedBackupOrderdata,
        });
        if (response.status == ApiStatus.STATUS_200) {
        } else if (response.status == ApiStatus.STATUS_500) {
          gToaster.warning({ title: "500 Server Error" });
        } else {
          gToaster.warning({ title: " Server Error" });
        }
      });
  }

  //========================
  //ACTIVE NEW/OLD FUNCTION
  function activeNewCancelCompleteOrders(name: string) {
    setTabBtnStatus(name);
    isActive(name);
    getFilteredOrderFun({ name: name });
  }
  function getFilteredOrderFun({
    name,
    myData,
  }: {
    name?: string;
    myData?: Array<Record<string, any>>;
  }) {
    const data = myData == undefined ? backupOrderdata : myData;

    const comVoidData = JSON.parse(
      JSON.stringify(myData || completeVoidOrders || [])
    );

    if (name == "newOrder") {
      const newOrders = data?.filter((ele: Record<string, any>) => {
        if (
          ele == null ||
          ele == undefined ||
          ele.track_order == null ||
          ele.track_order == undefined ||
          ele.track_order.length == 0
        ) {
          return [];
        } else if (
          ele?.track_order[+ele?.track_order?.length - 1]?.status ==
            "accepted" ||
          ele?.track_order[+ele?.track_order?.length - 1]?.status == "preparing"
        ) {
          return ele;
        }
      });
      if (newOrders) {
        setorderdata(newOrders);
        let length = newOrders?.length;
        dispatch(setTotalOrder(length));
      }
    }
    if (name == "readyToPick") {
      if (data) {
        const readyOrders = data.filter((ele: Record<string, any>) => {
          if (
            ele?.track_order[+ele?.track_order?.length - 1]?.status ==
            "ready_to_pick_up"
          ) {
            return ele;
          }
        });

        if (readyOrders) {
          setorderdata([...readyOrders]);
          dispatch(setTotalOrder(readyOrders?.length));
        }
      }
    }
    if (name == "completeOrder") {
      let completeOrders = comVoidData?.filter(
        (ele: Record<string, any>) => ele?.is_completed
      );
      setorderdata(completeOrders);
      dispatch(setCompleteOrder(completeOrders?.length));
    }
    if (name == "voidOrder") {
      let voidOrders = comVoidData?.filter(
        (ele: Record<string, any>) => ele.is_cancelled
      );
      setorderdata([...voidOrders]);
      dispatch(setAllVoidOrder(voidOrders?.length || 0));
    }
  }

  // NEW ORDER SOCKET
  useEffect(() => {
    socket.on("kds_order", (data: Record<string, any>) => {
      setBackupOrderdata((prev: Array<Record<string, any>>) => {
        let found = prev?.find(
          (item: Record<string, any>) => item?._id == data?._id
        );
        sound.play().catch((err) => {
          console.log(err);
        });
        if (!found || found == undefined) {
          setTabBtnStatus((prevStatus: string) => {
            if (prevStatus == "newOrder") {
              getFilteredOrderFun({
                name: "newOrder",
                myData: [...prev, data],
              });
            }
            return prevStatus;
          });

          return [...prev, data];
        } else {
          return [...prev];
        }
      });
    });
    socket.on("updated_order", (data: Record<string, any>) => {
      if (data?.is_completed || data?.is_cancelled) {
        setCompleteVoidOrders((pre: Array<Record<string, any>>) => {
          setTabBtnStatus((prevStatus: any) => {
            if (prevStatus === "completeOrder" || prevStatus === "voidOrder") {
              getFilteredOrderFun({
                name: prevStatus,
                myData: [...pre, data],
              });
            }

            return prevStatus;
          });
          return [...pre, data];
        });
        setBackupOrderdata((preOrder) => {
          let foundIndex = preOrder?.findIndex(
            (ele: Record<string, any>) => ele?._id == data?._id
          );

          if (foundIndex || foundIndex == 0) {
            let newData = [
              ...preOrder?.slice(0, foundIndex),
              ...preOrder?.slice(foundIndex + 1),
            ];

            setTabBtnStatus((prev: any) => {
              if (prev !== "completeOrder" && prev !== "voidOrder") {
                getFilteredOrderFun({
                  name: prev,
                  myData: newData,
                });
              }

              return prev;
            });

            return newData;
          }
          // getFilteredOrderFun({ name: tabBtn, myData: backupOrderdata });
        });
      } else {
        setBackupOrderdata((pre: any) => {
          let foundIndex = pre?.findIndex(
            (ele: Record<string, any>) => ele?._id == data?._id
          );
          if (foundIndex != -1) {
            let newData = [
              ...pre?.slice(0, foundIndex),
              data,
              ...pre?.slice(foundIndex + 1),
            ];
            let tabBtn = "";
            setTabBtnStatus((prevState: any) => {
              tabBtn = prevState;

              getFilteredOrderFun({ name: prevState, myData: newData });
              return prevState;
            });

            return newData;
          }

          // if (tabBtnStatus == "newOrder") {
          //   getFilteredOrderFun({ name: "newOrder", myData: backupOrderdata });
          // }
        });
      }
    });

    socket.on("store_day_end", (data: any) => {
      if (data.end_day) {
        setBackupOrderdata([]);
        setCompleteVoidOrders([]);
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (storeId) {
      getStoreProfile();
      getStoreDay();
    } else {
      setNotFound(true);
    }
  }, []);

  //THIS USE EFFECT FOR WHEN CLICK ON RELOAD BUTTIN "COUNTER" FROM STATES
  useEffect(() => {
    if (!!dayId) {
      getStoreOrdersData(dayId);
      getStoreVoidOrdersData(dayId);
    }
  }, [counter]);

  useEffect(() => {
    if (orderdata?.length) {
      if (orderdata.every((item) => item.is_new === false)) {
        sound.pause();
      } else {
        sound.loop = true;
        sound.play().catch((err) => {
          console.log(err);
        });
      }
    }
  }, [orderdata]);

  if (loading) {
    return (
      <Grid container spacing={1} sx={{ my: 1, px: 2 }}>
        <Shimmer />
      </Grid>
    );
  }
  //==================
  // CONNECTING SOCKET
  // function connect() {
  //   if (!connection) {
  //     if (storeId != null || storeId != undefined || storeId != "") {
  //       socket.connect();
  //       console.log("Connect");
  //       setConnection(true);
  //       addOrders();
  //       // updateOrder();
  //     }
  //   } else {
  //     console.log("Already Connected");
  //   }
  // }

  //=======================
  // ADD CHANNEL OF SOCKET
  // function addOrders() {
  //   socket.on("kds_order", (data) => {
  //     console.log(data, backupOrderdata, "added");
  //     let orderData = JSON.parse(JSON.stringify(backupOrderdata));
  //     orderData.unshift(data);
  //     console.log(orderData, "new add");

  //     setBackupOrderdata([...orderData]);
  //     getFilteredOrderFun();
  //   });
  // }

  //====================
  // UPDATE CHANNEL OF SOCKET
  // function updateOrder() {
  //   socket.on("updated_order", (data) => {
  //     console.log(backupOrderdata, "update socket_data");

  //     let orderData = JSON.parse(JSON.stringify(ghostOrders));

  //     let found: number = orderData.findIndex(
  //       (ele: Record<string, any>) => ele._id == data._id
  //     );
  //     if (found || found == 0) {
  //       orderData.splice(found, 1, data);
  //       console.log(orderData, "orderData in update order");
  //       ghostOrders = [...orderData];
  //       setBackupOrderdata([...orderData]);
  //       getFilteredOrderFun(tabBtnStatus, orderData);
  //     } else if (found < 0) {
  //       orderData.push(data);
  //       orderData = ghostOrders;
  //       setBackupOrderdata([...orderData]);
  //       getFilteredOrderFun();
  //     }
  //   });
  // }

  //==============================
  // DISCONNECT CHANNEL OF SOCKET
  // function disConnect() {
  //   setConnection(false);
  //   socket.close();
  //   socket.removeAllListeners();
  //   socket.disconnect();
  //   console.log("Socket Disconnected");
  // }
  console.log(orderdata, "orderdata");
  return (
    <>
      {notFound ? (
        <PageNotFound />
      ) : (
        <>
          <ButtonBar
            activeBtn={handleActive}
            activeNewOldBtn={activeNewCancelCompleteOrders}
            currentStatus={currentStatus}
            tabBtnStatus={tabBtnStatus}
          />
          <>
            <Grid container spacing={1} mb={5}>
              {orderdata?.map(
                (ele: Record<string, any>, orderIndex: number) => {
                  let foundStatus = ele?.track_order?.find(
                    (orderStatus: Record<string, any>) =>
                      orderStatus.status == "ready_to_pick_up" ||
                      (orderStatus.status == "completed" && orderStatus)
                  );

                  // THIS CODE FOR ORDER READY TO PICK BTN IF ALL PRODUCT FROM TAKE AWAY OR DINE IN ARE INCLUDE=TRUE, THEN SHOW BUTTON
                  let checkFound = undefined;
                  (ele?.take_away?.length > 0
                    ? ele?.take_away
                    : ele?.dine_in?.length > 0
                    ? ele?.dine_in
                    : []
                  ).map((ele: any) => {
                    let found = ele.product.every(
                      (pItem: any) => pItem.include == true
                    );
                    if (found) {
                      checkFound = found;
                    }
                  });

                  return (
                    <React.Fragment key={orderIndex}>
                      {ele?.order_type
                        ?.toLowerCase()
                        .replace(/\s+/g, "")
                        ?.includes(
                          currentStatus == "take_away"
                            ? "take_away"
                            : currentStatus == "dining"
                            ? "dine_in"
                            : ele?.order_type
                        ) && (
                        <Grid
                          item
                          xs={12}
                          md={
                            GridNumber == 2
                              ? 6
                              : GridNumber == 3
                              ? 4
                              : GridNumber == 4
                              ? 3
                              : GridNumber
                          }
                        >
                          <Card
                            sx={{
                              boxShadow: "0px 0px 5px 3px #d4d4d4",

                              // boxShadow:   min < fifty  ? "0px 0px 9px orange" :  fifty > twentyFive    ? "0px 0px 9px red": "0px 0px 10px grey",
                              borderRadius: "5px",
                              margin: "10px 0px 10px 10px",
                            }}
                          >
                            <CardContent>
                              <Grid container spacing={1} className="cardItems">
                                {ele.delivery_time !== null &&
                                  ele?.track_order?.length >= 2 &&
                                  ele?.track_order?.length <= 4 && (
                                    <Grid item xs={12}>
                                      <Counter singleOrderData={ele} />
                                    </Grid>
                                  )}
                                <Grid item xs={8}>
                                  <Typography paragraph={true}>
                                    <b>#{ele?.order_id}</b>
                                  </Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ textAlign: "center" }}>
                                  <Typography
                                    paragraph={true}
                                    className="orderTypeChip"
                                  >
                                    {textUpperCase(ele?.order_type)}
                                  </Typography>
                                  {ele?.delivery && (
                                    <Chip
                                      label="Delivery"
                                      color="success"
                                      variant="outlined"
                                    />
                                  )}
                                </Grid>

                                <Grid item xs={6}>
                                  <Typography paragraph={true}>
                                    Customer :
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    paragraph={true}
                                    textAlign={"right"}
                                  >
                                    {ele?.customer.name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography paragraph={true}>
                                    Created At :
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography
                                    paragraph={true}
                                    textAlign={"right"}
                                  >
                                    {moment(ele?.created_at).format(
                                      "MMMM Do YYYY, h:mm a"
                                    )}
                                  </Typography>
                                </Grid>

                                {ele?.table != null && (
                                  <>
                                    <Grid item xs={6}>
                                      <Typography paragraph={true}>
                                        <TableBarTwoToneIcon
                                          sx={{ fontSize: "22px" }}
                                        />
                                        {ele?.table ? ele?.table?.name : "-"}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography
                                        paragraph={true}
                                        textAlign={"right"}
                                      >
                                        <ChairAltTwoToneIcon
                                          sx={{ fontSize: "22px" }}
                                        />
                                        Seats-
                                        {ele?.table
                                          ? ele?.table?.sitting_capacity
                                          : "-"}
                                      </Typography>
                                    </Grid>
                                  </>
                                )}
                              </Grid>

                              <Grid
                                container
                                spacing={1}
                                sx={{
                                  backgroundColor: "#f2f2f299",
                                  borderRadius: "8px",
                                  padding: "12px 15px",
                                  my: "15px",
                                  maxHeight: "300px",
                                  overflowY: "scroll",
                                }}
                                className="cardItems"
                              >
                                {ele?.take_away?.length ||
                                ele?.dine_in?.length ? (
                                  <>
                                    <Grid item xs={12} md={12}>
                                      <Typography paragraph={true}>
                                        <b>Order Items </b>:
                                      </Typography>
                                      <Divider sx={{ mb: 1 }} />
                                    </Grid>
                                    <TakeAwayDineIn
                                      TabBtnStatus={tabBtnStatus}
                                      Orders={ele?.take_away}
                                      Element={ele}
                                      Title={"Take Away"}
                                      MainIndex={orderIndex}
                                      FoundStatus={foundStatus}
                                      handleCutItem={handleCutItem}
                                    />
                                    <TakeAwayDineIn
                                      TabBtnStatus={tabBtnStatus}
                                      Orders={ele?.dine_in}
                                      Title={"Dine In"}
                                      Element={ele}
                                      MainIndex={orderIndex}
                                      FoundStatus={foundStatus}
                                      handleCutItem={handleCutItem}
                                    />
                                  </>
                                ) : (
                                  ""
                                )}
                                {ele?.take_away_void?.length ||
                                ele?.dine_in_void?.length ? (
                                  <>
                                    <Grid container spacing={1}>
                                      <Grid item xs={12}>
                                        <Divider sx={{ my: 2 }} />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography paragraph={true}>
                                          <b>Voids Order Items </b>:
                                        </Typography>
                                      </Grid>
                                      {ele?.dine_in_void?.length ? (
                                        <TakeAwayVoidDineInVoid
                                          TabBtnStatus={tabBtnStatus}
                                          Orders={ele?.dine_in_void}
                                          Title={"Dine In Void"}
                                          Element={ele}
                                          FoundStatus={foundStatus}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {ele?.take_away_void?.length ? (
                                        <TakeAwayVoidDineInVoid
                                          TabBtnStatus={tabBtnStatus}
                                          Orders={ele?.take_away_void}
                                          Title={"Take Away Void"}
                                          Element={ele}
                                          FoundStatus={foundStatus}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Grid>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Grid>
                              {tabBtnStatus == "newOrder" && (
                                <>
                                  {!foundStatus && (
                                    <Grid container spacing={1}>
                                      {ele.is_new ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                          {btnIndex == orderIndex &&
                                          notifyloading ? (
                                            <CircularProgress color="warning" />
                                          ) : (
                                            <>
                                              <Button
                                                disabled={
                                                  ele?.track_order[
                                                    ele?.track_order?.length - 1
                                                  ]?.status ==
                                                    "ready_to_pick_up" ||
                                                  ele?.is_new == false
                                                    ? true
                                                    : false
                                                }
                                                variant="contained"
                                                // className="customBtn"
                                                size="large"
                                                color="error"
                                                fullWidth
                                                onClick={() =>
                                                  handleAcceptOrder(
                                                    ele,
                                                    orderIndex
                                                  )
                                                }
                                              >
                                                <VisibilitySharpIcon /> &nbsp;
                                                <b> Order Notified</b>
                                              </Button>
                                            </>
                                          )}
                                        </Grid>
                                      ) : (
                                        <Grid item xs={12} textAlign={"center"}>
                                          {btnIndex == orderIndex &&
                                          readyToPickLoading ? (
                                            <CircularProgress color="warning" />
                                          ) : (
                                            <>
                                              {checkFound && (
                                                <Button
                                                  // disabled={}
                                                  // ele.track_order[
                                                  //   ele?.track_order?.length - 1
                                                  // ]?.status ==
                                                  // "ready_to_pick_up"
                                                  //   ? true
                                                  //   : false
                                                  variant="contained"
                                                  // className="customBtn"
                                                  size="large"
                                                  color="warning"
                                                  fullWidth
                                                  onClick={() =>
                                                    handleReadyToPick(
                                                      ele,
                                                      orderIndex
                                                    )
                                                  }
                                                >
                                                  <AlarmOnSharpIcon /> &nbsp;
                                                  <b> Ready to pick</b>
                                                </Button>
                                              )}
                                            </>
                                          )}
                                        </Grid>
                                      )}
                                    </Grid>
                                  )}
                                </>
                              )}
                            </CardContent>
                          </Card>
                        </Grid>
                      )}
                    </React.Fragment>
                  );
                }
              )}
            </Grid>
          </>
        </>
      )}
    </>
  );
}

export default Home;
